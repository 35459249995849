<template>
  <Container>
    <h1 class="center">
      Welkom bij Dispuut
      <!-- This inline div groups the name for smaller screens -->
      <div style="display: inline-block">
        <span class="secondary">Ebrius </span>
        <span class="primary">Vespertina</span>
      </div>
    </h1>
    <img
      class="rounded card"
      src="/dispuut-bier.webp"
      alt="Dispuut Ebrius Vespertina"
      width="666.67"
      height="476.19"
      style="margin-bottom: 16px"
    />
    <p class="card" style="text-align: justify">
      <span class="bold" style="font-size: 1.25rem"
        >Dispuut <span class="secondary bold">Ebrius</span> <span class="primary">Vespertina </span></span
      >
      <div style="display: inline-block; font-size: 0.75rem">( D.E.V. )</div> is een gemengd dispuut dat aangesloten zit bij Indicium. Het dispuut is een hechte vrienden groep waar
      elke jaar een nieuwe jaarlage bij komt. Als groepering proberen we ervoor te zorgen dat wij het studenten leven op
      zoeken door elke maand activiteiten te houden voor onze leden onder het genot van een drankje. Elk jaar zal het
      dispuut als groep ook op vakantie gaan, een kennismakingskamp om de nieuwe leden te ontmoeten en vieren we onze
      verjaardag uitgebereid. De vrienden die je hier maakt worden vrienden voor het leven en lid van dit Dispuut zal je
      voor de rest van je leven blijven.
    </p>
  </Container>
</template>
