<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
  flex-grow: 1;
}
</style>
