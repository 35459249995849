<template>
  <div
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
    "
  >
    <h1>Sorry, deze pagina bestaat niet</h1>
    <p class="card center">Error code: 404</p>
  </div>
</template>
